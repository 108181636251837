import React, { Component } from 'react';
import Head from 'next/head';
import Home from '@studionand/depi/src/components/depi/Home';
import DepiLayout from '@studionand/depi/src/components/DepiLayout';

class IndexPage extends Component {
  static async getInitialProps({ dataStore }) {
    await dataStore.pages.loadNews();
    await dataStore.pages.loadEvents();
    // load page for about section
    await dataStore.pages.loadPage('3a027484-274b-4a22-bfcf-17e3b4eb7c27');
    // load page for experts section
    await dataStore.pages.loadPage('27168863-61ab-411b-8940-6a9aa6ba62df');
    // load page for funding section
    await dataStore.pages.loadPage('6ff5c8d5-3ede-425b-a16e-359b3f3d4f0e');
    // load page for förderbibel
    await dataStore.pages.loadPage('c51c26be-a8f4-4713-85bd-e9ce5a19eab0');
    return {};
  }

  render() {
    return (
      <DepiLayout>
        <Head>
          <title>DEPI - Startseite</title>
        </Head>
        <Home />
      </DepiLayout>
    );
  }
}

export default IndexPage;
